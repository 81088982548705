<template>
  <BaseModal @close="$emit('close')" cardWidth="490px" noPadding noToolbarPadding noExit>
    <template #toolbar>
      <div class="flex flex-col gap-4">
        <div class="flex flex-col gap-1">
          <BaseText type="heading" size="sm" class="text-text-loud">
            Start Spying on Competitors
          </BaseText>
          <BaseText type="body" size="sm" class="text-text-normal">
            Track & save every ad your competitors launch.
          </BaseText>
        </div>
        <div class="flex gap-2 mb-2">
          <button class="view-tab" :class="{'active': view === 'search'}"
          @click="view = 'search', adLibraryUrl = ''">
            Search Brand
            <transition name="tab">
              <div v-if="view === 'search'" class="tab-underline"/>
            </transition>
          </button>
          <button class="view-tab" :class="{'active': view === 'manual'}"
          @click="view = 'manual'">
            Add Manually
            <transition name="tab">
              <div v-if="view === 'manual'" class="tab-underline"/>
            </transition>
          </button>
        </div>
      </div>
      <button class="absolute top-3 right-3 p-1.5" @click="$emit('close')">
        <TagRemoveIcon stroke="#5E6678" />
      </button>
    </template>
    <template #default>
      <div class="flex flex-col w-full border-t border-border-normal">
        <!-- SEARCH VIEW -->
        <div v-if="view === 'search'" class="relative flex flex-col px-1.5 pt-5 w-full" :style="{height: `${searchListHeight}px`}">
          <!-- Search Bar -->
          <div class="w-full px-3.5">
            <div class="search-input-container group py-1.5 px-2 flex items-center rounded-md w-full">
              <div class="w-5 h-5">
                <SearchIcon class="transition-colors ease-in-out"
                :class="searchFocused ? 'text-icon-normal' : 'text-text-subdued group-hover:text-icon-muted'"/>
              </div>
              <input v-model="textSearch" placeholder="Search brands..." 
              @focus="searchFocused = true" @blur="searchFocused = false"
              class="ml-1 text-sm rounded flex items-center outline-none transition border-none w-full 
              focus:border-none text-gray-500 bg-white bg-opacity-0 max-w-none">
            </div>
          </div>
          <!-- Loading -->
          <div v-if="brandsLoading" class="relative flex-grow">
            <div class="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <BaseLoadingLogo />
            </div>
          </div>
          <!-- Brand not found -->
          <div v-else-if="brandResults.length === 0" class="relative flex-grow">
            <div class="flex flex-col gap-3 items-center absolute left-1/2 top-10 transform -translate-x-1/2">
              <div class="p-2 rounded-md bg-neutral-25">
                <img src="../../assets/icons/search-page-find.svg" class="w-6 h-6">
              </div>
              <div>
                <BaseText type="label" size="sm" class="text-black w-full text-center whitespace-nowrap">
                  Can't find the company you're looking for
                </BaseText>
                <BaseText type="body" size="sm" class="text-text-subdued w-full text-center whitespace-nowrap mt-1">
                  No worries, add the brand manually
                </BaseText>
              </div>
              <BaseButton primary iconOnly @click="view = 'manual', textSearch = ''">
                <div class="flex gap-1.5 items-center mx-1">
                  <PlusIcon className="text-white" stroke="white" />
                  <BaseText type="body" size="sm" class="text-white">
                    Add a Brand Manually
                  </BaseText>
                </div>
              </BaseButton>
            </div>
          </div>
          <!-- Brands list -->
          <div v-else id="brands-list" ref="brandsList" style="gap: 3px"
          class="flex flex-col mt-0.5 flex-grow overflow-y-scroll cursor-pointer">
            <button v-for="(brand, index) in brandResults" :key="`brand-${index}`"
            class="flex items-center py-2 pl-2 pr-3 rounded-md transition-colors border mx-3.5"
            @click="selectBrand(brand)"
            :class="[
              {'mt-2': index === 0}, 
              selectedBrands.some(selectedBrand => selectedBrand.id === brand.id) 
                ? 'selected bg-primary-red-10 border-primary-red-100' 
                : 'border-transparent hover:bg-neutral-25'
            ]">
              <img :src="brand.avatar" class="w-7 h-7 mr-3 rounded-md flex-shrink-0" :class="{'opacity-40': brand.isUserSubbed}"
              :style="{filter: brand.isUserSubbed ? 'grayscale(100%)' : ''}">
              <div class="flex gap-2 items-center flex-grow">
                <BaseText type="label" size="sm" class="text-text-loud text-left mr-2" :class="{'opacity-30': brand.isUserSubbed}">
                  {{ brand.name }}
                </BaseText>
                <BaseText v-if="brand.isUserSubbed" type="body" size="sm" class="ml-auto text-text-loud flex-shrink-0 opacity-30">
                  Already subscribed
                </BaseText>
                <BaseText v-else-if="brand.spyderData || brand.isTracked" type="body" size="sm" class="ml-auto text-text-normal flex-shrink-0">
                  {{ brand.adsSaved.toLocaleString() }} ads
                </BaseText>
                <BaseText v-else type="body" size="sm" class="ml-auto text-text-normal flex-shrink-0">
                  Not tracked yet
                </BaseText>
              </div>
              <transition name="checkmark">
                <NewCheckmarkIcon v-if="selectedBrands.some(selectedBrand => selectedBrand.id === brand.id)" 
                class="text-primary-red-100 ml-2" />
              </transition>
            </button>
            <infinite-loading 
              v-if="brandResults.length"
              :identifier="infiniteId"
              @infinite="getMoreBrands"
            >
              <div slot="spinner" class="relative -top-1">
                <BaseLoadingLogo :margin="0" />
              </div>
              <div slot="no-more" />
              <div slot="no-results" />
            </infinite-loading>
          </div>
        </div>
        <!-- MANUAL VIEW -->
        <div v-else class="p-5" :style="{height: `${searchListHeight}px`}">
          <BaseText type="label" size="sm" class="text-text-muted w-full">
            Facebook Ad Library URL
          </BaseText>
          <div class="flex gap-3 w-full mt-1">
            <div class="ad-library-input flex flex-grow items-center gap-2 px-2 py-1.5 rounded-md">
              <LinkSquareFlatIcon class="text-icon-normal flex-shrink-0" />
              <input v-model="adLibraryUrl" class="max-w-none w-full focus:outline-none" placeholder="facebook.com/ads/library..." />
            </div>
            <a class="ad-library-button flex flex-shrink-0 items-center gap-1.5 px-2 py-1.5 rounded-md"
            href="https://www.facebook.com/ads/library" target="_blank">
              <FacebookPlatformIcon :width="16" :height="16" />
              <BaseText type="label" size="sm" class="text-text-muted">
                Ad Library
              </BaseText>
              <ExternalLinkIcon :width="15" :height="15" class="text-icon-normal" />
            </a>
          </div>
          <div class="w-full px-3 py-2 rounded-md bg-neutral-25 mt-3">
            <BaseText type="body" size="sm" class="text-text-muted">
              Copy the Facebook Ad Library Page URL of a brand, <span class="font-semibold">not a keyword search</span>
            </BaseText>
          </div>
        </div>
        <!-- OPTIONS & ACTIONS -->
        <div class="flex flex-col gap-5 border-t border-border-normal p-5">
          <!-- Folder Selector dropdown -->
          <div class="w-full">
            <BaseText type="label" size="sm" class="w-full text-text-muted relative left-0.5">
              Location
            </BaseText>
            <div class="relative w-full h-8">
              <div class="absolute top-0 left-0 right-0 flex flex-col flex-grow mt-0.5 custom-shadow rounded-lg overflow-hidden">
                <button class="flex items-center w-full px-2 py-1.5 flex-nowrap"
                @click="foldersExpanded = !foldersExpanded">
                  <FolderIcon class="text-icon-normal mr-2 flex-shrink-0" />
                  <BaseText type="body" size="sm" class="flex-grow text-text-muted truncate text-left mr-2">
                    {{ selectedFolder.name || '' }}
                  </BaseText>
                  <div class="transform transition-transform flex-shrink-0"
                  :style="{transform: foldersExpanded ? 'scaleY(-1)' : ''}">
                    <ChevronIcon class="text-icon-normal" />
                  </div>
                </button>
                <div class="overflow-hidden rounded-b-lg border-t" :class="foldersExpanded ? 'border-border-normal' : 'border-transparent'"
                v-on-clickaway="() => { foldersExpanded = false }">
                  <div class="folder-dropdown flex flex-col gap-0.5 px-0.5 rounded-b-lg bg-white cursor-pointer overflow-y-scroll scrollbar-hide"
                  :class="{'expanded': foldersExpanded}" :style="{height: foldersExpanded ? `${Math.min(folderOptions.length * 34 + 6, 158)}px` : '0px'}">
                    <button v-for="(folder, index) in folderOptions" :key="`change-folder-${index}`"
                    class="group flex items-center rounded-lg px-1.5 py-1.5 transition duration-100 hover:bg-neutral-10 whitespace-nowrap"
                    :class="`${index === 0 ? 'mt-1' : index === folderOptions.length - 1 ? 'mb-1' : ''} ${foldersExpanded ? 'opacity-100' : 'opacity-0'}`"
                    @click="selectedFolder = folder, foldersExpanded = false">
                      <FolderIcon class="transition-colors text-neutral-400 group-hover:text-icon-normal mr-2 flex-shrink-0" />
                      <BaseText type="body" size="sm" class="text-text-muted flex-grow truncate text-left">
                        {{ folder.name }}
                      </BaseText>
                    </button>
                  </div>
                  <div v-if="foldersExpanded && folderOptions.length > 4" style="z-index: 100"
                  class="absolute left-0 bottom-0 right-0 h-4 bg-gradient-to-t from-white to-transparent opacity-70 pointer-events-none" />
                </div>
              </div>
            </div>
          </div>
          <div class="flex gap-3 w-full items-start">
            <!-- Spyder Credits -->
            <div class="relative flex items-center gap-1.5 bg-white pl-0.5 py-0.5 pr-3 rounded-full cursor-default 
            border flex-shrink-0 border-border-normal hover:bg-neutral-25 hover:border-transparent z-0"
            :class="{'animate-shake': animateCreditShake}"
            style="transition: background-color 150ms ease-in-out, border-color 150ms ease-in-out;"
            @mouseenter="showSpyderCreditsTooltip = true" @mouseleave="showSpyderCreditsTooltip = false">
              <img v-if="spyderSubscriptions.length > 0" src="../../assets/icons/spyder-token.png" class="w-7 h-7">
              <img v-else src="../../assets/icons/spyder-token-disabled.png" class="w-7 h-7" >
              <BaseText type="label" size="sm" class="text-text-muted">
                {{ spentSpyderCredits }}/{{ spyderLimit ? spyderLimit : 2 }} credits
              </BaseText>
              <!-- tooltip -->
              <transition>
                <div v-if="showSpyderCreditsTooltip" class="spyder-credit-tooltip pr-3 cursor-default">
                  <div class="relative rounded-xl w-max flex flex-col gap-1 items-center p-1.5 bg-neutral-900 shadow-md"
                  v-on-clickaway="() => {showSpyderCreditsTooltip = false}">
                    <img src="../../assets/icons/spyder-token.png" class="w-7 h-7 mb-0.5 mt-2.5">
                    <div class="w-full flex flex-col items-center gap-1 px-3 pb-3 pt-1">
                      <BaseText type="label" size="md" class="text-white">
                        Spyder Credits
                      </BaseText>
                      <BaseText v-if="spyderLimit - spentSpyderCredits > 0" type="body" size="sm" class="text-text-disabled">
                        You have {{ spyderLimit - spentSpyderCredits }} credits available.
                      </BaseText>
                      <BaseText v-else type="body" size="sm" class="text-text-disabled">
                        You have no remaining credits.
                      </BaseText>
                    </div>
                    <button class="tooltip-add-credits-button w-full justify-center flex items-center gap-1.5 py-1.5 pl-2 pr-3 rounded-md"
                    @click="$emit('buyCredits'), $emit('close')">
                      <PlusIcon class="" stroke="#A4ACB9" />
                      <BaseText type="label" size="sm" class="text-white">
                        Get More Spyder Credits
                      </BaseText>
                    </button>
                    <div class="arrow-right absolute left-full top-1/3" />
                  </div>
                </div>
              </transition>
            </div>
            <!-- Selected brands -->
            <transition-group v-if="showSelectedBrandsList" tag="div" class="flex flex-row-reverse flex-grow gap-2 flex-wrap overflow-y-scroll scrollbar-hide"
            :style="{maxHeight: `${selectedBrandsListMaxHeight}px`}">
              <div v-for="(brand, index) in selectedBrands" :key="`selected-brand-${index}`"
              class="flex items-center gap-1.5 p-1.5 rounded-full bg-primary-red-10 border border-primary-red-100">
                <img :src="brand.avatar" class="w-5 h-5 rounded-full">
                <BaseText type="label" size="sm" class="text-text-muted truncate whitespace-nowrap" style="max-width: 250px">
                  {{ brand.name }}
                </BaseText>
                <button class="rounded-full transition-colors hover:bg-primary-red-25"
                @click="removeSelection(index)">
                  <TagRemoveIcon stroke="#303546"/>
                </button>
              </div>
            </transition-group>
            <div v-else class="ml-auto self-center">
              <transition>
                <BaseText v-if="selectedBrands.length > 0" type="label" size="sm" class="text-primary-red-50">
                  {{ selectedBrands.length }} brands selected
                </BaseText>
              </transition>
            </div>
          </div>
        </div>
        <div class="flex justify-between w-full border-t border-border-normal p-5">
          <button id="add-spyder-tutorial-button" class="flex items-center gap-1.5 pl-2 py-1.5 pr-3 rounded-md transition-colors hover:bg-neutral-25"
          @click="$emit('openTutorialModal')">
            <PlayIcon class="text-icon-normal" />
            <div class="text-sm leading-5 font-medium text-text-muted">
              Tutorial
            </div>
          </button>
          <BaseButton 
            primary 
            :disabled="selectedBrands.length === 0 && adLibraryUrl.length === 0"
            :loading="addSpyderLoading"
            @click="handleAddSpyder"
            :class="selectedBrands.length === 0 && adLibraryUrl.length === 0 
              ? 'cursor-default text-text-disabled' 
              : 'text-white'"
          >
            <BaseText v-if="selectedBrands.length > 0 || (adLibraryUrl.length === 0 && view === 'search')" 
            type="label" size="sm" class="transition-colors">
              Add Spyder Brands
            </BaseText>
            <BaseText v-else type="label" size="sm" class="transition-colors">
              Add Brand Manually
            </BaseText>
          </BaseButton>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import FirebaseAPI from '@/api/firebase'
import ForeplayAPI from '@/api/foreplayServer'
import firebase from '@/api/config/FirebaseConfig'
import InfiniteLoading from 'vue-infinite-loading'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway2'

//Icons
import PlayIcon from '../globals/Icons/PlayIcon.vue'
import SearchIcon from '../globals/Icons/SearchIcon.vue'
import PlusIcon from '../globals/Icons/PlusIcon.vue'
import LinkSquareFlatIcon from '../globals/Icons/SpyderV2Icons/LinkSquareFlatIcon.vue'
import FacebookPlatformIcon from '../globals/Icons/FilterIcons/Platforms/FacebookPlatformIcon.vue'
import ExternalLinkIcon from '../globals/Icons/ExternalLinkIcon.vue'
import FolderIcon from '../globals/Icons/SpyderV2Icons/FolderIcon.vue'
import ChevronIcon from '../globals/Icons/ChevronIcon.vue'
import NewCheckmarkIcon from '../globals/Icons/NewCheckmarkIcon.vue'
import TagRemoveIcon from '../globals/Icons/TagRemoveIcon.vue'

export default {
  name: 'AddSpyderModal',
  mixins: [clickaway],
  components: {
    InfiniteLoading,
    PlayIcon,
    SearchIcon,
    PlusIcon,
    LinkSquareFlatIcon,
    FacebookPlatformIcon,
    ExternalLinkIcon,
    FolderIcon,
    ChevronIcon,
    NewCheckmarkIcon,
    TagRemoveIcon
  },
  props: {
    initialView: {
      type: String,
      default: 'search'
    },
    spyderSubscriptions: {
      type: Array,
      default: () => []
    },
    spyderLimit: {
      type: Number,
      default: 2
    },
    pageIdToAdd: {
      type: String,
      default: ''
    },
    initialFolder: {
      type: Object,
      default: () => ({ id: null, name: 'Default Folder' })
    }
  },
  data () {
    return {
      view: this.initialView,
      textSearch: '',
      adLibraryUrl: '',
      brandResults: [],
      selectedBrands: [],
      selectedFolder: this.initialFolder,
      creditsUsed: this.spyderSubscriptions.length,
      searchFocused: false,
      foldersExpanded: false,
      brandsLoading: true,
      addSpyderLoading: false,
      infiniteId: +new Date(),
      lastDocId: null,
      scrollTimeout: null,
      textSearchTimeout: null,
      showSpyderCreditsTooltip: false,
      animateCreditShake: false,
      resizeTimeout: null,
      searchListHeight: 324,
      showSelectedBrandsList: true,
      selectedBrandsListMaxHeight: 0
    }
  },
  beforeMount () {
    if (this.pageIdToAdd.length > 0) {
      this.adLibraryUrl = `https://www.facebook.com/ads/library/?active_status=all&ad_type=all&country=ALL&view_all_page_id=${this.pageIdToAdd}&search_type=page&media_type=all`
    }
  },
  async mounted() {
    // Responsive height computations
    this.computeSearchListHeight()
    this.determineShowSelectedBrandsList()
    this.computedSelectedBrandsListMaxHeight()
    window.addEventListener('resize', this.handleResize)

    // Fetch brands
    const { nextPage } = await this.fetchBrands()
    this.lastDocId = nextPage
    this.brandsLoading = false

    // Listener for fading scrollbar
    this.$nextTick(() => {
      this.$refs.brandsList.addEventListener('scroll', this.handleScroll)
    })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
    this.$refs.brandsList.removeEventListener('scroll', this.handleScroll)
  },
  computed: {
    ...mapGetters('BoardsModule', ['getFolders']),
    ...mapGetters('AuthModule', ['getUser', 'getTeam']),
    folderOptions () {
      const folders = this.getFolders.filter(folder => folder.id !== this.selectedFolder.id)
      if (this.selectedFolder.id) {
        folders.push({ id: null, name: 'Default Folder' })
      }
      return folders.sort((a, b) => a.name.localeCompare(b.name)) // sort alphabetically
    },
    spentSpyderCredits () {
      return this.creditsUsed + this.selectedBrands.length
    }
  },
  watch: {
    textSearch () {
      clearTimeout(this.textSearchTimeout)
      this.textSearchTimeout = setTimeout(async () => {
        this.brandsLoading = true
        const { nextPage } = await this.fetchBrands()
        this.lastDocId = nextPage
        this.brandsLoading = false
      }, 500)
    },
    adLibraryUrl (newVal) {
      if (newVal.length > 0) {
        this.textSearch = ''
        this.selectedBrands = []
      }
    }
  },
  methods: {
    ...mapActions('SpyderModule', ['fetchSpyderSubscriptions', 'fetchSpyderLimit']),
    ...mapActions('BoardsModule', ['fetchFolders']),
    ...mapMutations('BoardsModule', ['SET_FOLDERS']),
    selectBrand (selectedBrand) {
      if (this.spyderSubscriptions.some(sub => sub.id === selectedBrand.id)) return
      const isRemoving = this.selectedBrands.some(brand => brand.id === selectedBrand.id)
      if (!isRemoving && this.spentSpyderCredits >= this.spyderLimit) {
        this.animateCreditShake = true
        setTimeout(() => { this.animateCreditShake = false; }, 400);
        return
      }
      this.selectedBrands = isRemoving
        ? this.selectedBrands.filter(brand => brand.id !== selectedBrand.id)
        : [...this.selectedBrands, selectedBrand]
    },
    removeSelection (index) {
      this.selectedBrands = this.selectedBrands.filter((_, i) => i !== index)
    },
    async fetchBrands (lastDocId = null) {
      const filters = this.textSearch.length > 0
        ? { textSearch: this.textSearch, sort: 'relevance', adLibraryIdExists: true }
        : { sort: 'subscriberCount' }

      try {
        const data = await ForeplayAPI.Brands.getDiscoveryBrands(lastDocId, filters)
        const checkBrandIsTracked = async (brand) => {
          const { exists } = await this.checkSpyderExists(brand?.spyderData?.pageId || brand.adLibraryId)
          const isUserSubbed = this.spyderSubscriptions.some(sub => sub.id === brand.id)
          return { ...brand, isTracked: exists, isUserSubbed }
        }
        let results = await Promise.all(data.results.map(checkBrandIsTracked))
        // Don't show brands that the user is already subscribed to unless it's text search
        if (this.textSearch.length === 0) results = results.filter(brand => !brand.isUserSubbed)
        // Only set if it's the initial fetch
        if (!lastDocId) this.brandResults = results
        return { results, nextPage: data.nextPage }
      } catch (error) {
        console.error(error)
        this.$showAlert({
          type: 'error',
          message: 'Failed to fetch brands. See console for more details.'
        })
        return null
      } finally {
        if (!lastDocId) this.infiniteId += 1
      }
    },
    async getMoreBrands ($state) {
      console.log('fetching more brands')
      if (!this.lastDocId) {
        if (Object.keys($state).length) $state.complete()
        return
      }

      const { results, nextPage } = await this.fetchBrands(this.lastDocId)
      this.lastDocId = nextPage

      if (!this.lastDocId) {
        if (Object.keys($state).length) $state.complete()
        return
      }

      // Ensure results are all unique. Sometimes the same brand is returned in multiple pages
      const loadedBrandIds = new Set(this.brandResults.map(brand => brand.id))
      const uniqueResults = results.filter(brand => !loadedBrandIds.has(brand.id))

      this.brandResults = this.brandResults.concat(uniqueResults)
      if (Object.keys($state).length) $state.loaded()
    },
    async handleAddSpyder () {
	  if (this.addSpyderLoading) return
      if (this.selectedBrands.length === 0 && this.adLibraryUrl.length === 0) return
      if (this.spyderSubscriptions.length >= this.spyderLimit) {
        this.animateCreditShake = true
        setTimeout(() => { this.animateCreditShake = false; }, 400);
        return
      }
      this.addSpyderLoading = true;
      if (this.selectedBrands.length > 0) { // Add brands selected from search to Spyder
        const addSelectedBrand = async (brand) => {
          try {
            const { exists, docId, subscribers } = await this.checkSpyderExists(brand?.spyderData?.pageId || brand.adLibraryId)
            if (exists) {
              await this.addSubscriber(docId, subscribers)
            } else {
              await this.trackNewSpyder(brand?.spyderData?.pageId || brand.adLibraryId)
            }
          } catch (error) {
            console.error(error)
            this.$showAlert({
              type: 'error',
              message: 'Failed to add brand to Spyder. See console for more details.'
            })
          }
        }
        await Promise.all(this.selectedBrands.map(addSelectedBrand))
        const pageIds = this.selectedBrands.map(brand => brand?.spyderData?.pageId || brand.adLibraryId)
        await this.addToFolder(pageIds)

      } else if (this.adLibraryUrl.length > 0) { // Add brand from manual input to Spyder
        // Getting the url from the input and extracting the page id
        const url = new URL(this.adLibraryUrl)
        const params = new URLSearchParams(url.search)
        if (params.get('search_type') && params.get('search_type') !== 'page') {
          this.addSpyderLoading = false
          this.$showAlert({
            type: 'error',
            message: 'Invalid link. Must be a brand page link.'
          })
          return
        }
        const pageId = params.get('view_all_page_id')
        try {
          const { exists, docId, subscribers } = await this.checkSpyderExists(pageId)
          if (exists) {
            await this.addSubscriber(docId, subscribers)
          } else {
            await this.trackNewSpyder(pageId)
          }
        } catch (error) {
          console.error(error)
          this.$showAlert({
            type: 'error',
            message: 'Failed to add brand to Spyder. See console for more details.'
          })
        }
        await this.addToFolder([pageId])
      }
    },
    async checkSpyderExists (pageId) {
      const db = firebase.firestore()
      const snapshot = await db.collection('fb_ads_page_track')
        .where('pageId', '==', `${pageId}`)
        .get()
      return {
        exists: !snapshot.empty,
        docId: snapshot.docs[0]?.id,
        subscribers: snapshot.docs[0]?.data()?.subscribers
      }
    },
    async addSubscriber (docId, currentSubs) {
      // adds the user information to the subscribers object
      const subscriptionId = this.getTeam?.id || this.getUser.user_id
      if (currentSubs.includes(subscriptionId)) return // Should never happen

      if (currentSubs.length) {
        await FirebaseAPI.Spyder.update(docId, { subscribers: [...currentSubs, subscriptionId], live: true })
      } else {
        await FirebaseAPI.Spyder.update(docId, { subscribers: [subscriptionId], live: true })
      }
    },
    async trackNewSpyder (pageId) {
      if (!pageId) {
        this.addSpyderLoading = false;
        return this.$showAlert({
          type: 'error',
          message: 'pageId is not valid'
        })
      }
	  await ForeplayAPI.Brands.createSpyderBrand(pageId);
    },
    async addToFolder (pageIds) {
      // add to folder object under the folder that has been selected by the user update global state
      if (!this.selectedFolder.id) {
        // if (this.$route.query.id) await this.$router.push({ name: 'SpyderBrandsView' }).catch(() => {})
        await this.fetchSpyderSubscriptions()
        await this.fetchFolders()
        this.$emit('spyderAdded')
        this.$emit('close')
      } else {
        try {
          const newBrands = this.selectedFolder.spyderBrands ? [...this.selectedFolder.spyderBrands, ...pageIds] : pageIds
          this.SET_FOLDERS(this.getFolders.map(folder => 
            folder.id === this.selectedFolder.id 
              ? { ...folder, spyderBrands: newBrands } 
              : folder
          ))
          await FirebaseAPI.Folders.update(this.selectedFolder.id, { spyderBrands: newBrands })
          await this.fetchSpyderSubscriptions()
          await this.fetchFolders()
          // if (this.$route.query.id) await this.$router.push({ name: 'SpyderBrandsView' }).catch(() => {})
        } catch (error) {
          console.error(error)
          this.$showAlert({
            type: 'error',
            message: 'Failed to add brand to custom folder. See console for more details.'
          })
        } finally {
          this.$emit('spyderAdded')
          this.$emit('close')
        }
      }
    },
    formatTime (time) {
      const updatedAgo = ((new Date().getTime() - (time * 1000)) / 3600000)
      return updatedAgo < 1 ? `${Math.floor(updatedAgo * 60)}m` : `${Math.floor(updatedAgo)}h`
    },
    handleScroll () {
      const scrollableElement = this.$refs.brandsList;
      scrollableElement.style.setProperty('--scrollbar-thumb-bg', '#DFE1E7');
      clearTimeout(this.scrollTimeout);
      this.scrollTimeout = setTimeout(() => {
        scrollableElement.style.setProperty('--scrollbar-thumb-bg', 'white');
      }, 500);
    },

    // Below are some semi-scuffed methods for handling responsive height changes.
    // They affect the height of the search list and the selected brands list.
    // It assumes that the modal is 760px tall max and adjusts the height of the lists accordingly.
    handleResize () {
      if (this.resizeTimeout) clearTimeout(this.resizeTimeout)
      this.resizeTimeout = setTimeout(() => {
        this.computeSearchListHeight()
        this.determineShowSelectedBrandsList()
        this.computedSelectedBrandsListMaxHeight()
      }, 100)
    },
    computeSearchListHeight () {
      const heightDiff = 760 - window.innerHeight
      this.searchListHeight = heightDiff > 0 ? 324 - heightDiff : 324
    },
    determineShowSelectedBrandsList () {
      this.showSelectedBrandsList = window.innerHeight > 800
    },
    computedSelectedBrandsListMaxHeight () {
      if (!this.showSelectedBrandsList) return
      this.selectedBrandsListMaxHeight = window.innerHeight - 725
    }
  }
}
</script>

<style scoped>
.view-tab {
  position: relative;
  padding: 0.375rem 0.625rem;
  border-radius: 0.375rem;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #5E6678; /* text-text-normal */
  background-color: transparent;
  cursor: pointer;
  user-select: none; /* Standard */
  transition: color 150ms ease-in-out, background-color 150ms ease-in-out;
}
.view-tab:hover {
  color: #303546; /* text-text-muted */
  background-color: #F6F8FA; /* bg-neutral-25 */
}
.view-tab.active {
  color: #303546; /* text-text-muted */
  background-color: transparent;
  font-weight: 500;
}
.tab-underline {
  position: absolute;
  top: calc(100% + 0.5rem);
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 0px;
  z-index: 40001;
  border-bottom: 1px solid #06070F; /* border-icon-loud */
}
.search-input-container {
  --placeholder-color: #5E6678;
  transition: background-color 150ms ease-in-out;
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.08), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
  background-color: transparent;
}
.search-input-container:hover {
  background-color: white;
  --placeholder-color: black;
}
.search-input-container:focus-within {
  background-color: transparent;
  --placeholder-color: #A4ACB9;
}
.search-input-container input::placeholder {
  transition: color 150ms ease-in-out;
  color: var(--placeholder-color);
}
.ad-library-input {
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.08), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
  --al-placeholder-color: #808899; /* text-text-subdued */
}
.ad-library-input:focus-within {
  --al-placeholder-color: #C1C7D0 !important; /* text-neutral-200 */
}
.ad-library-input:hover {
  --al-placeholder-color: #5E6678;
}
.ad-library-input input::placeholder {
  transition: color 150ms ease-in-out;
  color: var(--al-placeholder-color);
}
.ad-library-button {
  transition: box-shadow 150ms ease-in-out, background-color 150ms ease-in-out;
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.08), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
  background-color: white;
}
.ad-library-button:hover {
  box-shadow: none;
  background-color: #F6F8FA; /* bg-neutral-25 */
}
@property --scrollbar-thumb-bg {
  syntax: '<color>';
  inherits: true;
  initial-value: white;
}
#brands-list {
  transition: --scrollbar-thumb-bg 250ms ease-in-out;
}
#brands-list::-webkit-scrollbar {
  width: 3px;
}
#brands-list::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb-bg);
  border-radius: 18px;
}
.folder-dropdown {
  position: relative;
  z-index: 100;
  transition: height 100ms ease-in-out;
}
.custom-shadow {
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.08), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
}
.spyder-credit-tooltip {
  position: absolute;
  right: 100%;
  top: 33%;
  transform: translateY(-36%);
}
.tooltip-add-credits-button {
  transition: border-color 150ms ease-in-out, background-color 150ms ease-in-out;
  border: 1px solid;
  border-color: rgba(255, 255, 255, 0.10);
  background-color: rgba(255, 255, 255, 0.15);
}
.tooltip-add-credits-button:hover {
  border-color: rgba(255, 255, 255, 0.15);
  background-color: rgba(255, 255, 255, 0.20);
}
.arrow-right {
  width: 0; 
  height: 0; 
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #06070F;
}
.animate-shake {
  animation: shake 0.3s cubic-bezier(.36,.07,.19,.97) both;
}
@keyframes shake {
  0% { transform: translateX(0) }
 25% { transform: translateX(3px) }
 50% { transform: translateX(-3px) }
 75% { transform: translateX(2px) }
 100% { transform: translateX(0) }
}
.v-enter-active, .v-leave-active {
  transition: opacity 150ms ease-in-out;
}
.v-enter-from, .v-enter, .v-leave-to {
  opacity: 0;
}
.v-enter-to, .v-leave-from {
  opacity: 1;
}
.checkmark-enter-active, .checkmark-leave-active {
  transition: opacity 150ms ease-in-out, transform 150ms ease-in-out, width 150ms ease-in-out, margin-left 150ms ease-in-out;
}
.checkmark-enter-from, .checkmark-enter, .checkmark-leave-to {
  opacity: 0;
  width: 0;
  margin-left: 0;
  transform: scaleX(0);
}
.checkmark-enter-to, .checkmark-leave-from {
  opacity: 1;
  width: 20px;
  margin-left: 0.5rem;
  transform: scaleX(1);
}
.tab-enter-active, .tab-leave-active {
  transition: width 150ms ease-in-out;
}
.tab-enter-from, .tab-enter, .tab-leave-to {
  width: 0;
}
.tab-enter-to, .tab-leave-from {
  width: 100%;
}
</style>